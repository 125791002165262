import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Option } from 'react-bootstrap';
import {
  faAdd,
  faSubtract,
  faCartPlus,
  faCheckCircle,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { getCategoriesApi } from '../../../../api/category';
import { getProductImageApi } from '../../../../api/product';
import useAuth from '../../../../hooks/useAuth';
import useConfig from '../../../../hooks/useConfig';

import SignIn from '../SignIn/SignIn';

import Modal from '../../../Modal';

import './Menu.scss';
import { addProductToCartApi } from '../../../../api/cart';

const imagePlaceholder = require('../../../../assets/img/webp/image-placeholder.webp');

export default function Menu(props) {
  const { user, isLoading } = useAuth();
  const { acceptingOrders } = useConfig();
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [categories, setCategories] = useState(null);
  const [isLoadingCategories, setisLoadingCategories] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: 'Default',
    body: 'Hello',
  });

  useEffect(() => {
    if (categories === null)
      getCategoriesApi()
        .then((response) => {
          setCategories(response.categories);
          setSelectedCategory(response.categories[0].index);
          setisLoadingCategories(false);
        })
        .catch((e) => {
          setisLoadingCategories(false);
        });
  });

  useEffect(() => {
    if (user && !isLoading) {
      setisAuthenticated(true);
    }
  }, [isLoading, user]);

  const handleClose = () => {
    setModalShow(false);
    setModalInfo({ ...modalInfo, show: false });
  };

  const addItemToCart = (product) => {
    addProductToCartApi(product);
  };

  const handleShowLogin = () => {
    setModalTitle('Iniciar Sesión');
    setModalShow(true);
  };

  const setModalTitle = (title) => {
    setModalInfo({ ...modalInfo, title: title });
  };

  return (
    <div className="menu-container">
      <Modal
        show={modalInfo.show}
        handleClose={handleClose}
        title={modalInfo.title}
        body={modalInfo.body}
      />
      <Modal
        show={modalShow}
        handleClose={handleClose}
        title={modalInfo.title}
        body={
          <SignIn handleClose={handleClose} setModalTitle={setModalTitle} />
        }
      />
      <div className="menu-container__categories">
        {!isLoadingCategories &&
          categories &&
          categories.map((category) => {
            return (
              <>
                {category.hasActiveProducts && (
                  <div
                    className="menu-container__categories__category"
                    onClick={() => setSelectedCategory(category.index)}
                    style={
                      selectedCategory === category.index
                        ? { color: 'green' }
                        : {}
                    }
                  >
                    {category.name}
                  </div>
                )}
              </>
            );
          })}
      </div>
      <div className="menu-container__products">
        {!isLoadingCategories &&
          categories &&
          categories.map((category) => {
            return category.products.map((product) => {
              product.categoryId = category._id;

              return (
                <>
                  {product.active && (
                    <ProductCard
                      acceptingOrders={acceptingOrders}
                      selectedCategory={selectedCategory}
                      categoryIndex={category.index}
                      isAuthenticated={isAuthenticated}
                      key={product._id}
                      product={product}
                      setModalInfo={setModalInfo}
                      addItemToCart={addItemToCart}
                      handleShowLogin={handleShowLogin}
                    />
                  )}
                </>
              );
            });
          })}
      </div>
    </div>
  );
}

function ProductCard(props) {
  const {
    acceptingOrders,
    product,
    setModalInfo,
    isAuthenticated,
    selectedCategory,
    categoryIndex,
    handleShowLogin,
  } = props;
  const [addingToCart, setAddingToCart] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [productData, setProductData] = useState(product);
  const [variant, setVariant] = useState(0);
  const [image, setProductImage] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(productData.variant[0].price || 0);

  useEffect(() => {
    if (product.image && image === null) {
      getProductImageApi(product.image).then((response) => {
        setProductImage(response);
      });
    } else {
      setProductImage(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVariantChange = (e) => {
    e.preventDefault();
    const variants = productData.variant;

    variants.forEach((element, index) => {
      if (element._id === e.target.value) {
        setPrice(productData.variant[index].price);
        setVariant(index);
      }
    });
  };

  const addToCart = (prod) => {
    setAddingToCart(true);
    prod.quantity = quantity;
    prod.variantIndex = variant;
    addProductToCartApi(prod)
      .then((response) => {
        setQuantity(1);
        setAddingToCart(false);
        setAddedToCart(true);
        setTimeout(() => setAddedToCart(false), 2000);
      })
      .catch((e) => {
        console.error(e);
        setTimeout(() => {
          addToCart(prod);
        }, 200);
      });
  };

  const shortStr = (str, len) => {
    if (str.length > len) {
      return str.substring(0, len - 3) + '...';
    } else {
      return str;
    }
  };

  return (
    <>
      {selectedCategory === categoryIndex && (
        <div className="menu-container__products__card">
          <div>
            <div>
              <div className="menu-container__products__card__image">
                <img
                  src={image ? image : imagePlaceholder}
                  className="img-fluid "
                  alt="sample"
                />
              </div>
              <div className="menu-container__products__card__title">
                <div className="product-name">
                  {shortStr(productData.name, 20)}
                </div>
                <div className="product-price">
                  {price * quantity}€{' '}
                  <span style={{ fontSize: '9px' }}>IVA incluído</span>{' '}
                </div>
              </div>
            </div>
            <Form.Select
              disabled={product.variant.length == 1}
              onChange={(e) => handleVariantChange(e)}
            >
              {productData.variant &&
                productData.variant.map((variant, index) => (
                  <option value={variant._id} key={variant._id}>
                    {variant.type}
                  </option>
                ))}
            </Form.Select>
            <div>
              {!productData.phoneOnly && acceptingOrders && (
                <div className="add-to-cart-button-container">
                  <div className="add-to-cart-button-container_quantity">
                    <span>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          setQuantity(quantity !== 1 ? quantity - 1 : quantity);
                        }}
                      >
                        <FontAwesomeIcon icon={faSubtract} />
                      </Button>
                    </span>
                    <span style={{ padding: '0px 15px 0px 15px' }}>
                      {quantity}
                    </span>
                    <span>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <FontAwesomeIcon icon={faAdd} />
                      </Button>
                    </span>
                  </div>
                </div>
              )}
              {productData.phoneOnly && acceptingOrders && (
                <div className="encargo-telefonico">
                  Disponible por encargo telefónico
                </div>
              )}
              {!acceptingOrders && (
                <div className="encargo-telefonico">
                  Lo sentimos, actualmente no aceptamos pedidos web
                </div>
              )}
              <div className="add-to-cart-button-container">
                <div className="add-to-cart-button-container_button">
                  {!productData.phoneOnly && acceptingOrders && (
                    <div>
                      <Button
                        variant="success"
                        onClick={() => {
                          if (isAuthenticated) {
                            addToCart(product);
                          } else {
                            handleShowLogin();
                          }
                        }}
                        disabled={addingToCart || addedToCart}
                      >
                        {!addingToCart && !addedToCart && (
                          <FontAwesomeIcon icon={faCartPlus} />
                        )}
                        {addingToCart && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {addedToCart && (
                          <FontAwesomeIcon icon={faCheckCircle} />
                        )}
                        {!addedToCart && <span> Añadir al carrito</span>}
                        {addedToCart && <span> Añadido</span>}
                      </Button>
                    </div>
                  )}
                  {(productData.phoneOnly || !acceptingOrders) && (
                    <a href="tel:948043179">
                      <Button variant="dark">
                        <FontAwesomeIcon icon={faPhone} />
                        {'  '}
                        <span>948043179</span>
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function ProductModalBody(props) {
  const {
    product,
    image,
    addItemToCart,
    setModalInfo,
    isAuthenticated,
    addingToCart,
    addedToCart,
    quantity,
    setQuantity,
    handleShowLogin,
  } = props;

  return (
    <div className="product-modal-container">
      <div className="product-modal-image">
        <img
          src={image}
          className="img-card"
          alt={`${product.name} el pollo paulino`}
        />
      </div>
      <div className="product-modal-price">
        <p>{product.price}€</p>
      </div>
      <div className="product-modal-description">
        <p>{product.description}</p>
      </div>

      <div className="bottom-product-card-bar">
        <span className="bottom-product-card-bar__price"></span>
        <div className="">
          <span>
            <Button
              variant="outline-danger"
              onClick={() => {
                setQuantity(quantity !== 1 ? quantity - 1 : quantity);
              }}
            >
              <FontAwesomeIcon icon={faSubtract} />
            </Button>
          </span>
          <span style={{ padding: '0px 15px 0px 15px' }}>{quantity}</span>
          <span>
            <Button
              variant="outline-success"
              onClick={() => {
                setQuantity(quantity + 1);
              }}
            >
              <FontAwesomeIcon icon={faAdd} />
            </Button>
          </span>
        </div>
        <span>
          <Button
            variant="success"
            onClick={() => {
              setModalInfo({ show: false });
              if (!isAuthenticated) {
                handleShowLogin();
              } else {
                addItemToCart(product);
              }
            }}
            disabled={addingToCart || addedToCart}
          >
            {!addingToCart && !addedToCart && (
              <FontAwesomeIcon icon={faCartPlus} />
            )}
            {addingToCart && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {addedToCart && <FontAwesomeIcon icon={faCheckCircle} />}
            {!addedToCart && <span> Añadir al carrito</span>}
            {addedToCart && <span> Añadido</span>}
          </Button>
        </span>
      </div>
    </div>
  );
}
